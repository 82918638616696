/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetIncidentpredictions200,
  GetIncidentpredictionsEndorsed200,
  GetIncidentpredictionsEndorsedParams,
  GetIncidentpredictionsParams,
  GetIncidentsIdPredictions200,
  GetIncidentsIdPredictionsParams,
  GetPredictions200,
  GetPredictionsId200,
  GetPredictionsParams,
  GetPredictionsRedmaps200,
  GetPredictionsRedmapsParams,
  GetPredictionsSettings200,
  GetPredictionsSettingsPhoenix200,
  GetPredictionsSettingsSpark200,
  PostPredictionsCreate200,
  PostPredictionsRedmaps201,
  PostPredictionsRedmapsBody,
  PostPredictionsRedmapsPreview200,
  PostPredictionsRedmapsPreviewBody,
  PostPredictionsRefresh200,
  PostPredictionsSettings200,
  PostPredictionsSettingsPhoenix200,
  PostPredictionsSettingsSpark200,
  PostPredictionsStartRun200,
  PostPredictionsUpload200,
  PostPredictionsUploadPreview201,
  PostPredictionsUploadPreviewBody,
  PutPredictionsIdArchive200,
  PutPredictionsIdAssess200,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";
import type {
  AssessPredictionInput,
  CreatePhoenixPredictionsSettingsInput,
  CreatePredictionsSettingsInput,
  CreateSparkPredictionsSettingsInput,
  RefreshPredictionInput,
  StartRunInput,
  UploadPredictionInput,
} from "./types/predictions.yml";

/**
 * Gets a list of incidents in a bounding box, then gets the predictions on each of the individual incidents
 * @summary Get incident predictions
 */
export const getIncidentpredictions = (
  params?: GetIncidentpredictionsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentpredictions200>> => {
  return axios.get(`/incidentpredictions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetIncidentpredictionsQueryKey = (
  params?: GetIncidentpredictionsParams,
) => {
  return [`/incidentpredictions`, ...(params ? [params] : [])] as const;
};

export const getGetIncidentpredictionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentpredictions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetIncidentpredictionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentpredictions>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIncidentpredictionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncidentpredictions>>
  > = ({ signal }) =>
    getIncidentpredictions(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentpredictions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIncidentpredictionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentpredictions>>
>;
export type GetIncidentpredictionsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get incident predictions
 */
export const useGetIncidentpredictions = <
  TData = Awaited<ReturnType<typeof getIncidentpredictions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetIncidentpredictionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentpredictions>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIncidentpredictionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Gets a list of incidents in a bounding box, then gets the predictions on each of the individual incidents
 * @summary Get incident predictions
 */
export const getIncidentpredictionsEndorsed = (
  params?: GetIncidentpredictionsEndorsedParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentpredictionsEndorsed200>> => {
  return axios.get(`/incidentpredictions/endorsed`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetIncidentpredictionsEndorsedQueryKey = (
  params?: GetIncidentpredictionsEndorsedParams,
) => {
  return [
    `/incidentpredictions/endorsed`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetIncidentpredictionsEndorsedQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentpredictionsEndorsed>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetIncidentpredictionsEndorsedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentpredictionsEndorsed>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIncidentpredictionsEndorsedQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncidentpredictionsEndorsed>>
  > = ({ signal }) =>
    getIncidentpredictionsEndorsed(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentpredictionsEndorsed>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIncidentpredictionsEndorsedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentpredictionsEndorsed>>
>;
export type GetIncidentpredictionsEndorsedQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get incident predictions
 */
export const useGetIncidentpredictionsEndorsed = <
  TData = Awaited<ReturnType<typeof getIncidentpredictionsEndorsed>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetIncidentpredictionsEndorsedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentpredictionsEndorsed>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIncidentpredictionsEndorsedQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the predictions for an incident
 */
export const getIncidentsIdPredictions = (
  id: string,
  params?: GetIncidentsIdPredictionsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentsIdPredictions200>> => {
  return axios.get(`/incidents/${id}/predictions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetIncidentsIdPredictionsQueryKey = (
  id: string,
  params?: GetIncidentsIdPredictionsParams,
) => {
  return [`/incidents/${id}/predictions`, ...(params ? [params] : [])] as const;
};

export const getGetIncidentsIdPredictionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentsIdPredictions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdPredictionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdPredictions>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIncidentsIdPredictionsQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncidentsIdPredictions>>
  > = ({ signal }) =>
    getIncidentsIdPredictions(id, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentsIdPredictions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIncidentsIdPredictionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentsIdPredictions>>
>;
export type GetIncidentsIdPredictionsQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get the predictions for an incident
 */
export const useGetIncidentsIdPredictions = <
  TData = Awaited<ReturnType<typeof getIncidentsIdPredictions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdPredictionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdPredictions>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIncidentsIdPredictionsQueryOptions(
    id,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the entire list of predictions
 * @summary Get all predictions
 */
export const getPredictions = (
  params?: GetPredictionsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPredictions200>> => {
  return axios.get(`/predictions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetPredictionsQueryKey = (params?: GetPredictionsParams) => {
  return [`/predictions`, ...(params ? [params] : [])] as const;
};

export const getGetPredictionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPredictions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetPredictionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPredictions>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPredictionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPredictions>>> = ({
    signal,
  }) => getPredictions(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPredictions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPredictionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPredictions>>
>;
export type GetPredictionsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get all predictions
 */
export const useGetPredictions = <
  TData = Awaited<ReturnType<typeof getPredictions>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetPredictionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPredictions>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPredictionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns a single prediction from the ID
 * @summary Get a single prediction
 */
export const getPredictionsId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPredictionsId200>> => {
  return axios.get(`/predictions/${id}`, options);
};

export const getGetPredictionsIdQueryKey = (id: string) => {
  return [`/predictions/${id}`] as const;
};

export const getGetPredictionsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPredictionsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPredictionsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPredictionsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPredictionsId>>
  > = ({ signal }) => getPredictionsId(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPredictionsId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPredictionsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPredictionsId>>
>;
export type GetPredictionsIdQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get a single prediction
 */
export const useGetPredictionsId = <
  TData = Awaited<ReturnType<typeof getPredictionsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPredictionsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPredictionsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Archives a prediction.
 * @summary Archive prediction
 */
export const putPredictionsIdArchive = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutPredictionsIdArchive200>> => {
  return axios.put(`/predictions/${id}/archive`, undefined, options);
};

export const getPutPredictionsIdArchiveMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPredictionsIdArchive>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPredictionsIdArchive>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPredictionsIdArchive>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return putPredictionsIdArchive(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPredictionsIdArchiveMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPredictionsIdArchive>>
>;

export type PutPredictionsIdArchiveMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Archive prediction
 */
export const usePutPredictionsIdArchive = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPredictionsIdArchive>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPutPredictionsIdArchiveMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Assess prediction
 */
export const putPredictionsIdAssess = (
  id: string,
  assessPredictionInput: AssessPredictionInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutPredictionsIdAssess200>> => {
  return axios.put(`/predictions/${id}/assess`, assessPredictionInput, options);
};

export const getPutPredictionsIdAssessMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPredictionsIdAssess>>,
    TError,
    { id: string; data: AssessPredictionInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPredictionsIdAssess>>,
  TError,
  { id: string; data: AssessPredictionInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPredictionsIdAssess>>,
    { id: string; data: AssessPredictionInput }
  > = (props) => {
    const { id, data } = props ?? {};

    return putPredictionsIdAssess(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPredictionsIdAssessMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPredictionsIdAssess>>
>;
export type PutPredictionsIdAssessMutationBody = AssessPredictionInput;
export type PutPredictionsIdAssessMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Assess prediction
 */
export const usePutPredictionsIdAssess = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPredictionsIdAssess>>,
    TError,
    { id: string; data: AssessPredictionInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPutPredictionsIdAssessMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns a CSV containing potential impacts
 * @summary Get potential impacts CSV
 */
export const getPredictionsIdPotentialImpactsCsv = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string>> => {
  return axios.get(`/predictions/${id}/potential-impacts.csv`, options);
};

export const getGetPredictionsIdPotentialImpactsCsvQueryKey = (id: string) => {
  return [`/predictions/${id}/potential-impacts.csv`] as const;
};

export const getGetPredictionsIdPotentialImpactsCsvQueryOptions = <
  TData = Awaited<ReturnType<typeof getPredictionsIdPotentialImpactsCsv>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPredictionsIdPotentialImpactsCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPredictionsIdPotentialImpactsCsvQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPredictionsIdPotentialImpactsCsv>>
  > = ({ signal }) =>
    getPredictionsIdPotentialImpactsCsv(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPredictionsIdPotentialImpactsCsv>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPredictionsIdPotentialImpactsCsvQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPredictionsIdPotentialImpactsCsv>>
>;
export type GetPredictionsIdPotentialImpactsCsvQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get potential impacts CSV
 */
export const useGetPredictionsIdPotentialImpactsCsv = <
  TData = Awaited<ReturnType<typeof getPredictionsIdPotentialImpactsCsv>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPredictionsIdPotentialImpactsCsv>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPredictionsIdPotentialImpactsCsvQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * ### DEPRECATED

Kept for backwards compatibility - use [start prediction run](#/paths/predictions-start-run/post)
instead.

This is now a wrapper that requests a prediction run and returns the first prediction that
was created.

 * @summary [Deprecated] Create prediction
 */
export const postPredictionsCreate = (
  startRunInput: StartRunInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsCreate200>> => {
  return axios.post(`/predictions/create`, startRunInput, options);
};

export const getPostPredictionsCreateMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsCreate>>,
    TError,
    { data: StartRunInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsCreate>>,
  TError,
  { data: StartRunInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsCreate>>,
    { data: StartRunInput }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsCreate(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsCreate>>
>;
export type PostPredictionsCreateMutationBody = StartRunInput;
export type PostPredictionsCreateMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary [Deprecated] Create prediction
 */
export const usePostPredictionsCreate = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsCreate>>,
    TError,
    { data: StartRunInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPostPredictionsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Request a new prediction run for an incident. By default, this will create multiple
predictions, one for each supported simulator.

 * @summary Start prediction run
 */
export const postPredictionsStartRun = (
  startRunInput: StartRunInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsStartRun200>> => {
  return axios.post(`/predictions/start-run`, startRunInput, options);
};

export const getPostPredictionsStartRunMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsStartRun>>,
    TError,
    { data: StartRunInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsStartRun>>,
  TError,
  { data: StartRunInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsStartRun>>,
    { data: StartRunInput }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsStartRun(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsStartRunMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsStartRun>>
>;
export type PostPredictionsStartRunMutationBody = StartRunInput;
export type PostPredictionsStartRunMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Start prediction run
 */
export const usePostPredictionsStartRun = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsStartRun>>,
    TError,
    { data: StartRunInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPostPredictionsStartRunMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get red maps by their red map day.
 * @summary Get red maps
 */
export const getPredictionsRedmaps = (
  params: GetPredictionsRedmapsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPredictionsRedmaps200>> => {
  return axios.get(`/predictions/redmaps`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetPredictionsRedmapsQueryKey = (
  params: GetPredictionsRedmapsParams,
) => {
  return [`/predictions/redmaps`, ...(params ? [params] : [])] as const;
};

export const getGetPredictionsRedmapsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPredictionsRedmaps>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params: GetPredictionsRedmapsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPredictionsRedmaps>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPredictionsRedmapsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPredictionsRedmaps>>
  > = ({ signal }) =>
    getPredictionsRedmaps(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPredictionsRedmaps>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPredictionsRedmapsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPredictionsRedmaps>>
>;
export type GetPredictionsRedmapsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get red maps
 */
export const useGetPredictionsRedmaps = <
  TData = Awaited<ReturnType<typeof getPredictionsRedmaps>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params: GetPredictionsRedmapsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getPredictionsRedmaps>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPredictionsRedmapsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Finalise the creation of a red map prediction, after generating a preview using `POST /predictions/redmaps/preview`.
 * @summary Create red map
 */
export const postPredictionsRedmaps = (
  postPredictionsRedmapsBody: PostPredictionsRedmapsBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsRedmaps201>> => {
  return axios.post(
    `/predictions/redmaps`,
    postPredictionsRedmapsBody,
    options,
  );
};

export const getPostPredictionsRedmapsMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsRedmaps>>,
    TError,
    { data: PostPredictionsRedmapsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsRedmaps>>,
  TError,
  { data: PostPredictionsRedmapsBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsRedmaps>>,
    { data: PostPredictionsRedmapsBody }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsRedmaps(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsRedmapsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsRedmaps>>
>;
export type PostPredictionsRedmapsMutationBody = PostPredictionsRedmapsBody;
export type PostPredictionsRedmapsMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Create red map
 */
export const usePostPredictionsRedmaps = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsRedmaps>>,
    TError,
    { data: PostPredictionsRedmapsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPostPredictionsRedmapsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Process uploaded shapefiles into GeoJSON and return a preview.
Shapefiles should be uploaded as a ZIP archive using `POST /fileupload`.

Uploaded ZIPs must contain one `.shp` and one `.prj` file.

This does not create the actual red map prediction. The previews should
then be used with `POST /predictions/redmaps` with the required metadata
to finalise the creation.

The locations of the generated previews are not stored anywhere—if a
preview's URI is lost, a new preview will need to be generated.

 * @summary Generate preview of red map
 */
export const postPredictionsRedmapsPreview = (
  postPredictionsRedmapsPreviewBody: PostPredictionsRedmapsPreviewBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsRedmapsPreview200>> => {
  return axios.post(
    `/predictions/redmaps/preview`,
    postPredictionsRedmapsPreviewBody,
    options,
  );
};

export const getPostPredictionsRedmapsPreviewMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsRedmapsPreview>>,
    TError,
    { data: PostPredictionsRedmapsPreviewBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsRedmapsPreview>>,
  TError,
  { data: PostPredictionsRedmapsPreviewBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsRedmapsPreview>>,
    { data: PostPredictionsRedmapsPreviewBody }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsRedmapsPreview(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsRedmapsPreviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsRedmapsPreview>>
>;
export type PostPredictionsRedmapsPreviewMutationBody =
  PostPredictionsRedmapsPreviewBody;
export type PostPredictionsRedmapsPreviewMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Generate preview of red map
 */
export const usePostPredictionsRedmapsPreview = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsRedmapsPreview>>,
    TError,
    { data: PostPredictionsRedmapsPreviewBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getPostPredictionsRedmapsPreviewMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Regenerate predictions for all active incidents
 */
export const postPredictionsRefresh = (
  refreshPredictionInput: RefreshPredictionInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsRefresh200>> => {
  return axios.post(`/predictions/refresh`, refreshPredictionInput, options);
};

export const getPostPredictionsRefreshMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsRefresh>>,
    TError,
    { data: RefreshPredictionInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsRefresh>>,
  TError,
  { data: RefreshPredictionInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsRefresh>>,
    { data: RefreshPredictionInput }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsRefresh(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsRefreshMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsRefresh>>
>;
export type PostPredictionsRefreshMutationBody = RefreshPredictionInput;
export type PostPredictionsRefreshMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Regenerate predictions for all active incidents
 */
export const usePostPredictionsRefresh = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsRefresh>>,
    TError,
    { data: RefreshPredictionInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPostPredictionsRefreshMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get predictions settings
 */
export const getPredictionsSettings = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPredictionsSettings200>> => {
  return axios.get(`/predictions/settings`, options);
};

export const getGetPredictionsSettingsQueryKey = () => {
  return [`/predictions/settings`] as const;
};

export const getGetPredictionsSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPredictionsSettings>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPredictionsSettings>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPredictionsSettingsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPredictionsSettings>>
  > = ({ signal }) => getPredictionsSettings({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPredictionsSettings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPredictionsSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPredictionsSettings>>
>;
export type GetPredictionsSettingsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get predictions settings
 */
export const useGetPredictionsSettings = <
  TData = Awaited<ReturnType<typeof getPredictionsSettings>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPredictionsSettings>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPredictionsSettingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create predictions settings
 */
export const postPredictionsSettings = (
  createPredictionsSettingsInput: CreatePredictionsSettingsInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsSettings200>> => {
  return axios.post(
    `/predictions/settings`,
    createPredictionsSettingsInput,
    options,
  );
};

export const getPostPredictionsSettingsMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsSettings>>,
    TError,
    { data: CreatePredictionsSettingsInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsSettings>>,
  TError,
  { data: CreatePredictionsSettingsInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsSettings>>,
    { data: CreatePredictionsSettingsInput }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsSettings(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsSettings>>
>;
export type PostPredictionsSettingsMutationBody =
  CreatePredictionsSettingsInput;
export type PostPredictionsSettingsMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Create predictions settings
 */
export const usePostPredictionsSettings = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsSettings>>,
    TError,
    { data: CreatePredictionsSettingsInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPostPredictionsSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get predictions settings for SPARK
 */
export const getPredictionsSettingsSpark = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPredictionsSettingsSpark200>> => {
  return axios.get(`/predictions/settings/spark`, options);
};

export const getGetPredictionsSettingsSparkQueryKey = () => {
  return [`/predictions/settings/spark`] as const;
};

export const getGetPredictionsSettingsSparkQueryOptions = <
  TData = Awaited<ReturnType<typeof getPredictionsSettingsSpark>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPredictionsSettingsSpark>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPredictionsSettingsSparkQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPredictionsSettingsSpark>>
  > = ({ signal }) => getPredictionsSettingsSpark({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPredictionsSettingsSpark>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPredictionsSettingsSparkQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPredictionsSettingsSpark>>
>;
export type GetPredictionsSettingsSparkQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get predictions settings for SPARK
 */
export const useGetPredictionsSettingsSpark = <
  TData = Awaited<ReturnType<typeof getPredictionsSettingsSpark>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPredictionsSettingsSpark>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPredictionsSettingsSparkQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create predictions settings for SPARK
 */
export const postPredictionsSettingsSpark = (
  createSparkPredictionsSettingsInput: CreateSparkPredictionsSettingsInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsSettingsSpark200>> => {
  return axios.post(
    `/predictions/settings/spark`,
    createSparkPredictionsSettingsInput,
    options,
  );
};

export const getPostPredictionsSettingsSparkMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsSettingsSpark>>,
    TError,
    { data: CreateSparkPredictionsSettingsInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsSettingsSpark>>,
  TError,
  { data: CreateSparkPredictionsSettingsInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsSettingsSpark>>,
    { data: CreateSparkPredictionsSettingsInput }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsSettingsSpark(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsSettingsSparkMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsSettingsSpark>>
>;
export type PostPredictionsSettingsSparkMutationBody =
  CreateSparkPredictionsSettingsInput;
export type PostPredictionsSettingsSparkMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Create predictions settings for SPARK
 */
export const usePostPredictionsSettingsSpark = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsSettingsSpark>>,
    TError,
    { data: CreateSparkPredictionsSettingsInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getPostPredictionsSettingsSparkMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get predictions settings for Phoenix
 */
export const getPredictionsSettingsPhoenix = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetPredictionsSettingsPhoenix200>> => {
  return axios.get(`/predictions/settings/phoenix`, options);
};

export const getGetPredictionsSettingsPhoenixQueryKey = () => {
  return [`/predictions/settings/phoenix`] as const;
};

export const getGetPredictionsSettingsPhoenixQueryOptions = <
  TData = Awaited<ReturnType<typeof getPredictionsSettingsPhoenix>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPredictionsSettingsPhoenix>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPredictionsSettingsPhoenixQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPredictionsSettingsPhoenix>>
  > = ({ signal }) =>
    getPredictionsSettingsPhoenix({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPredictionsSettingsPhoenix>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPredictionsSettingsPhoenixQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPredictionsSettingsPhoenix>>
>;
export type GetPredictionsSettingsPhoenixQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get predictions settings for Phoenix
 */
export const useGetPredictionsSettingsPhoenix = <
  TData = Awaited<ReturnType<typeof getPredictionsSettingsPhoenix>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPredictionsSettingsPhoenix>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPredictionsSettingsPhoenixQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create predictions settings for Phoenix
 */
export const postPredictionsSettingsPhoenix = (
  createPhoenixPredictionsSettingsInput: CreatePhoenixPredictionsSettingsInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsSettingsPhoenix200>> => {
  return axios.post(
    `/predictions/settings/phoenix`,
    createPhoenixPredictionsSettingsInput,
    options,
  );
};

export const getPostPredictionsSettingsPhoenixMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsSettingsPhoenix>>,
    TError,
    { data: CreatePhoenixPredictionsSettingsInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsSettingsPhoenix>>,
  TError,
  { data: CreatePhoenixPredictionsSettingsInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsSettingsPhoenix>>,
    { data: CreatePhoenixPredictionsSettingsInput }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsSettingsPhoenix(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsSettingsPhoenixMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsSettingsPhoenix>>
>;
export type PostPredictionsSettingsPhoenixMutationBody =
  CreatePhoenixPredictionsSettingsInput;
export type PostPredictionsSettingsPhoenixMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Create predictions settings for Phoenix
 */
export const usePostPredictionsSettingsPhoenix = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsSettingsPhoenix>>,
    TError,
    { data: CreatePhoenixPredictionsSettingsInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getPostPredictionsSettingsPhoenixMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Finalise the creation of a manual prediction, after generating a preview using `POST /predictions/upload/preview`.
 * @summary Upload prediction
 */
export const postPredictionsUpload = (
  uploadPredictionInput: UploadPredictionInput,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsUpload200>> => {
  return axios.post(`/predictions/upload`, uploadPredictionInput, options);
};

export const getPostPredictionsUploadMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsUpload>>,
    TError,
    { data: UploadPredictionInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsUpload>>,
  TError,
  { data: UploadPredictionInput },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsUpload>>,
    { data: UploadPredictionInput }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsUpload(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsUpload>>
>;
export type PostPredictionsUploadMutationBody = UploadPredictionInput;
export type PostPredictionsUploadMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Upload prediction
 */
export const usePostPredictionsUpload = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsUpload>>,
    TError,
    { data: UploadPredictionInput },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPostPredictionsUploadMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Convert uploaded shapefiles into GeoJSON and combine them. The exported
manual prediction should be uploaded as a single ZIP archive using `POST
/fileupload`.

The expected contents of the ZIP archive are as follows:
  - Required files for points: `points.cpg`, `points.dbf`, `points.prj`
    and `points.shp`.
  - Required files for polygons: `polygons.cpg`, `polygons.dbf`,
    `polygons.prj` and `polygons.shp`.

Ideally, the ZIP archive should also contain a `points.shx` and a
`polygons.shx`, but both are optional.

This does not create the actual manual prediction. The preview should
then be used with `POST /predictions/upload` plus any required metadata
to finalise the creation.

The S3 location of the generated preview is not stored anywhere—if a URI
is lost, a new one will need to be generated.

 * @summary Generate preview of manual prediction
 */
export const postPredictionsUploadPreview = (
  postPredictionsUploadPreviewBody: PostPredictionsUploadPreviewBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostPredictionsUploadPreview201>> => {
  return axios.post(
    `/predictions/upload/preview`,
    postPredictionsUploadPreviewBody,
    options,
  );
};

export const getPostPredictionsUploadPreviewMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsUploadPreview>>,
    TError,
    { data: PostPredictionsUploadPreviewBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPredictionsUploadPreview>>,
  TError,
  { data: PostPredictionsUploadPreviewBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPredictionsUploadPreview>>,
    { data: PostPredictionsUploadPreviewBody }
  > = (props) => {
    const { data } = props ?? {};

    return postPredictionsUploadPreview(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPredictionsUploadPreviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPredictionsUploadPreview>>
>;
export type PostPredictionsUploadPreviewMutationBody =
  PostPredictionsUploadPreviewBody;
export type PostPredictionsUploadPreviewMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Generate preview of manual prediction
 */
export const usePostPredictionsUploadPreview = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPredictionsUploadPreview>>,
    TError,
    { data: PostPredictionsUploadPreviewBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getPostPredictionsUploadPreviewMutationOptions(options);

  return useMutation(mutationOptions);
};
