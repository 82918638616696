import { useState } from "react";

const useIsOffline = (): boolean => {
  const [isOffline, setIsOffline] = useState<boolean>(!window.navigator.onLine);
  window.addEventListener("online", () => setIsOffline(false));
  window.addEventListener("offline", () => setIsOffline(true));

  return isOffline;
};

export default useIsOffline;
