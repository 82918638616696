import {
  Clock,
  HeaderIndicator,
  NavSubheader,
  useIsMinWidth,
  useSyncCSSProperty,
  type NavSubheaderProps,
} from "@app/design-system";
import getConfig from "next/config";
import useDimensions from "react-cool-dimensions";
import styled from "styled-components";
import { useDeveloperOptions } from "../../util/DeveloperOptionsProvider/DeveloperOptionsProvider";
import useIsOffline from "./useIsOffline";

const StyledContent = styled.div`
  flex: 1;
`;

const StyledIndicators = styled.div`
  display: flex;
  gap: 1rem;
`;

export const APP_NAV_SUBHEADER_HEIGHT_PROPERTY = "--app-nav-subheader-height";

const AppNavSubheader = ({ children, ...props }: NavSubheaderProps) => {
  const { publicRuntimeConfig } = getConfig();

  const { options } = useDeveloperOptions();

  const { observe: subheaderRef, height } = useDimensions();

  useSyncCSSProperty({
    name: APP_NAV_SUBHEADER_HEIGHT_PROPERTY,
    value: `${height}px`,
  });

  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const isOffline = useIsOffline();

  const { ENVIRONMENT } = publicRuntimeConfig;

  return (
    <>
      <NavSubheader {...props} ref={subheaderRef}>
        <StyledContent>{children}</StyledContent>
        {isTabletLandscapeAndAbove && (
          <StyledIndicators>
            <Clock data-testid="app-bar-clock" />
          </StyledIndicators>
        )}
      </NavSubheader>
      {ENVIRONMENT !== "prod" && options.isEnvironmentIndicatorEnabled && (
        <HeaderIndicator variant="tenant" data-testid="tenant-indicator">
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
          Currently operating in the {ENVIRONMENT.toUpperCase()} environment
        </HeaderIndicator>
      )}
      {isOffline && (
        <HeaderIndicator variant="offline" data-testid="offline-indicator">
          You are currently operating offline.
        </HeaderIndicator>
      )}
    </>
  );
};

export default AppNavSubheader;
